import * as Yup from "yup";

const specialCharsRegex = /^[A-Za-z0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
const message = 'Evita el uso de los caracteres "&", "Ñ" y las tildes.';

const especialCharsWitnÑ = /^[A-Za-zÑñ0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_{|}~]*$/;
const messageWithÑ = 'Evita el uso de los caracteres "&", y las tildes.';

export const shipperAddressSchema = Yup.object({
  address_line: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  city: Yup.string()
    .trim()
    .required()
    .matches(especialCharsWitnÑ, messageWithÑ),
  company_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  postal_code: Yup.string()
    .trim()
    .required("El código postal es requerido")
    .min(4, "Mínimo 4 caracteres")
    .matches(/^[a-zA-Z0-9-]+$/, {
      message: 'No se permiten espacios ni caracteres especiales, excepto "-"',
      excludeEmptyString: true,
    }),
  email: Yup.string()
    .trim()
    .email()
    .required()
    .matches(specialCharsRegex, message),
  person_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  country_code: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  phone_number: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
});

export const consigneeAddressSchema = Yup.object({
  taxes_id: Yup.string().when("country_name", {
    is: (country_name: string) => ["México", "Perú", "Argentina"].includes(country_name),
    then: Yup.string()
      .trim()
      .required("Este campo es obligatorio para México, Perú y Argentina")
      .matches(specialCharsRegex, message),
    otherwise: Yup.string().trim().optional(),
  }),
  tax_identification: Yup.string().when("country_name", {
    is: "Brasil",
    then: Yup.string()
      .trim()
      .required("El número de identificación fiscal es obligatorio para Brasil"),
    otherwise: Yup.string().trim().optional(),
  }),
  tax_identification_type: Yup.string().when("country_name", {
    is: "Brasil",
    then: Yup.string()
      .trim()
      .required("Debe seleccionar CNPJ o CPF para Brasil"),
    otherwise: Yup.string().trim().optional(),
  }),
  ie_rg: Yup.string().trim().optional(),
  address_line: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  city: Yup.string()
    .trim()
    .required()
    .matches(especialCharsWitnÑ, messageWithÑ),
  company_name: Yup.string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message),
  country_code: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  postal_code: Yup.string()
    .trim()
    .required("El código postal es requerido")
    .min(4, "Mínimo 4 caracteres")
    .matches(/^[a-zA-Z0-9-]+$/, {
      message: 'No se permiten espacios ni caracteres especiales, excepto "-"',
      excludeEmptyString: true,
    }),
  email: Yup.string()
    .trim()
    .email()
    .required()
    .matches(specialCharsRegex, message),
  person_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  phone_number: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
});
