import { Backdrop, Box, Card, CardContent, CircularProgress, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { SkeletonInformation } from "commons/skeleton-information";
import { COURIERS } from "enums/corriers";
import {
  ShipalProps,
  fullFormProp,
} from "components/molecules/order-forms/types";
import {
  getBodyQuote,
  selectedEconomicCarrier,
  validationDueDiligenceByCouriers,
} from "modules/draft/utils/courrier";
import React, { useEffect, useState } from "react";
import { findCouriersWithDraft, quote } from "services/serviceV2/orders";
import { fnCreateDrawProps } from "components/molecules/order-forms/types";
import { iconCOO, iconDHL, iconFEDEX } from "assets/imgs";
import { courierSelected } from "./utils";
import { currentFormat } from "utils/convert";
import { SHIPPING_TYPE } from "enums/shipping";

export type CardCarrier = {
  currency: string;
  delivery: string;
  needCarrierMark: string;
  remote_area_charge?: number;
  safe: number;
  safeCurrency: string;
  surcharge: string;
  totalPrice: string;
  typeAccount: string;
};

type Props = {
  data: Partial<fullFormProp>;
  fnSave: fnCreateDrawProps;
  isForQuote?: boolean;
  typeShipping: SHIPPING_TYPE;
};

export const CheapestOptions = ({
  data,
  fnSave,
  isForQuote,
  typeShipping,
}: Props) => {
  const [carrierSelect, setCarrierSelect] = useState(0);
  const [, setIsCarrier] = useState(false);
  const {
    reset,
    error,
    data: dataCarriers,
    mutate: mutateCarrier,
    isLoading
  } = useMutation({
    mutationKey: ["couriers"],
    mutationFn: isForQuote ? quote : findCouriersWithDraft,
    onSuccess: async (resolve) => {
      const couriers = resolve.data as [];

      const flag = await courierSelected(
        data.shipal?.selected_carrier,
        couriers,
        setCarrierSelect,
        onSaveShipal
      );
      if (!flag) {
        await onSaveShipal(selectedEconomicCarrier(resolve.data));
      }
    },
  });

  useEffect(() => {
    // const validateCarrier = validationDueDiligenceByCouriers(data);

    // console.log(validateCarrier);
    // if (validateCarrier) {
    setIsCarrier(true);
    if (isForQuote) {
      if (
        data.consignee &&
        data.shipper &&
        data.package_information &&
        data.package_information.length
      ) {
        mutateCarrier(data as fullFormProp);
      } else {
        reset();
      }
    } else {
      mutateCarrier(getBodyQuote(data));
    }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.consignee,
    data.shipper,
    data.package_information,
    data.declared_value,
  ]);

  const setCarrierShipal = (
    valueCarrier: CardCarrier
  ): Partial<ShipalProps> => {
    return {
      ...data.shipal,
      currency: valueCarrier.currency,
      declared_currency: data.is_international_shippment ? "USD" : "COP",
      selected_carrier: valueCarrier.delivery,
      insurance_value: valueCarrier.safe,
      need_insurance_carrier_mark: valueCarrier.needCarrierMark,
      shipal_account: valueCarrier.typeAccount,
    };
  };

  const onSaveShipal = async (values: CardCarrier) => {
    const bodySave = setCarrierShipal(values);
    let totalPayment = values.totalPrice;
    if (bodySave.insurance) {
      totalPayment = totalPayment + bodySave.insurance_value;
    }
    const newData = {
      ...data,
      total_payment: totalPayment,
      shipment_value: values.totalPrice,
      remote_area_charge: values.remote_area_charge,
    };
    return await fnSave(newData, "shipal", bodySave);
  };

  const handleSelect = async (index: number) => {
    setCarrierSelect(index);
    await onSaveShipal(dataCarriers.data[index]);
  };

  return (
    <>
      <Backdrop sx={{ zIndex: 9999, color: "#5a00b3" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {dataCarriers?.data?.length > 0 ? (
        <Card>
          <CardContent
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              p: 1.5,
              px: "24px",
              minHeight: "20rem",
              justifyContent: "center",
            }}
          >
            {dataCarriers.data.map((carrier: CardCarrier, key: number) => (
              <Card
                key={key}
                sx={{
                  borderRadius: "5px",
                  border:
                    carrierSelect === key
                      ? "solid 1px #7e00fb"
                      : "solid 1px #F0F2F5",
                  display: "flex",
                  backgroundColor:
                    carrierSelect === key ? "#FCFAFF !important" : "white",
                  justifyContent: "center",
                  boxShadow:
                    carrierSelect !== key
                      ? "unset !important"
                      : "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12) !important",
                  alignItems: "center",
                  cursor: "pointer",
                  height: "5.5rem",
                }}
                onClick={() => handleSelect(key)}
              >
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                    p: "0 !important",
                    px: "19px !important",
                    gap: 2,
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "15rem",
                      height: "5rem",
                    }}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        alignSelf: "baseline",
                        justifySelf: "baseline",
                      }}
                      alt="carrierImage"
                      src={
                        carrier.delivery === COURIERS.COORDINADORA
                          ? iconCOO
                          : carrier.delivery === COURIERS.DHL
                          ? iconDHL
                          : iconFEDEX
                      }
                    />
                  </Stack>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "14px !important" }}
                    >
                      {carrier.delivery}
                    </Typography>
                    {/* <Typography variant="subtitle1" color="grey">
                    {carrier.}
                  </Typography> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        alignSelf: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1rem",
                      }}
                    >
                      {currentFormat(parseInt(carrier.totalPrice))}{" "}
                      {carrier.currency}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Card>
      ) : (
        <SkeletonInformation
          typeShipping={typeShipping}
          isforQuote={isForQuote}
          error={Boolean(error)}
          height="20rem"
        />
      )}
    </>
  );
};
