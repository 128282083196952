import { SHIPPING_TYPE } from "enums/shipping";
import { OrderStatus, OrderType } from "services/serviceV2/orders/types";
import { fullFormProp } from "./types";

export const defaultValue = (type: SHIPPING_TYPE): Partial<fullFormProp> => ({
  shipment_date: "",
  shipment_value: 0,
  declared_value: 0,
  total_payment: 0,
  shipper_account: "",
  is_international_shippment: SHIPPING_TYPE.INTERNATIONAL === type,
  contents: "",
  package_type: "",
  order_status: OrderStatus.DRAFT,
  order_type: OrderType.SHIPAL,
  due_diligence: {
    consignee_completed: false,
    shipper_completed: false,
    carrier_selected: false,
    minimum_items_filled: false,
    export_declaration_completed: false,
    export_declaration_required: false,
  },
  shipper: {
    address_line: "",
    city: "",
    company_name: "",
    email: "",
    person_name: "",
    phone_number: "",
    country_code: "",
    country_name: "",
    postal_code: "",
    document_number: "",
    state: "",
    dane_code: "",
    is_default_address: false,
  },
  consignee: {
    city: "",
    company_name: "",
    email: "",
    person_name: "",
    phone_number: "",
    postal_code: "",
    country_code: "",
    country_name: "",
    document_number: "",
    state: "",
    address_line: "",
    dane_code: "",
  },
  shipal: {
    currency: "",
    insurance: false,
    insurance_value: 0,
    need_insurance_carrier_mark: "",
    selected_carrier: "",
    shipal_account: "683162114",
    declared_currency: type === SHIPPING_TYPE.INTERNATIONAL ? "USD" : "CO",
    remote_area_charge: 0,
  },
  export_declaration: {
    export_reason: "",
    export_reason_code: "",
    declared_value: 0,
    inter_consignee: "Cosg32424",
    invoice_date: "",
    invoice_number: 0,
    shipment_purpose: "COMMERCIAL",
    signature_name: "N/A",
  },
  package_information: [],
});
