import { UseFormSetValue } from "react-hook-form";
import { ConsigneeProps, ShipperProps } from "components/molecules/order-forms/types";

export const setValueWithNoteBook = (
  setValue: UseFormSetValue<ShipperProps | ConsigneeProps>,
  values: Partial<ShipperProps | ConsigneeProps>
) => {
  values.address_line &&
    setValue("address_line", values.address_line, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });

  values.address_line &&
    setValue("address_line2", values.address_line2,{
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch:true
    });

  values.address_line &&
    setValue("address_line3", values.address_line3, {
      shouldDirty: true,
      shouldValidate: true,
    });

  values.city &&
    setValue("city", values.city, { shouldDirty: true, shouldValidate: true });

  values.country_name &&
    setValue("country_name", values.country_name, {
      shouldDirty: true,
      shouldValidate: true,
    });

  values.country_code &&
    setValue("country_code", values.country_code, {
      shouldDirty: true,
      shouldValidate: true,
    });

  values.company_name &&
    setValue("company_name", values.company_name, {
      shouldDirty: true,
      shouldValidate: true,
    });
  
  values.dane_code &&
    setValue("dane_code", values.dane_code, {
      shouldDirty: true,
      shouldValidate: true,
    });

  values.postal_code &&
    setValue("postal_code", values.postal_code, {
      shouldDirty: true,
      shouldValidate: true,
    });

  values.email &&
    setValue("email", values.email, {
      shouldDirty: true,
      shouldValidate: true,
    });

  values.person_name &&
    setValue("person_name", values.person_name, {
      shouldDirty: true,
      shouldValidate: true,
    });

  values.phone_number &&
    setValue("phone_number", values.phone_number, {
      shouldDirty: true,
      shouldValidate: true,
    });
  
  values.state &&
    setValue("state", values.state, {
      shouldDirty: true,
      shouldValidate: true,
    });

  /*   const keys = Object.keys(labelsValuesWithNoteBook);
  keys.forEach((key: string) => {
    saveSetValue(
      setValue,
      key,
      values[
        labelsValuesWithNoteBook[key as keyof typeof labelsValuesWithNoteBook]
      ]
    );
  });
  
  saveSetValue(setValue, "email", values.Contact.Email);
  saveSetValue(setValue, "person_name", values.Contact.PersonName);
  saveSetValue(setValue, "phone_number", values.Contact.PhoneNumber); */
};
