import * as yup from "yup";

const specialCharsRegex = /^[A-Za-z0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
const message = 'Evita el uso de los caracteres "&", "Ñ" y las tildes.';

const especialCharsWitnÑ = /^[A-Za-zÑñ0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_{|}~]*$/;
const messageWithÑ = 'Evita el uso de los caracteres "&", y las tildes.';

export const addressSchema = yup.object({
  address_line: yup.string().trim().matches(specialCharsRegex, message),
  address_line2: yup
    .string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message),
  address_line3: yup
    .string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message),
  address_type: yup.string().trim(),
  document_number: yup
    .string()
    .trim()
    .matches(specialCharsRegex, message)
    .optional(),
  city: yup.string().trim().matches(especialCharsWitnÑ, messageWithÑ),
  country_name: yup.string().trim(),
  country_code: yup.string().trim().matches(specialCharsRegex, message),
  company_name: yup.string().trim().matches(specialCharsRegex, message),
  postal_code: yup.string().trim().matches(specialCharsRegex, message),
  state: yup
    .string()
    .trim()
    .matches(especialCharsWitnÑ, messageWithÑ)
    .optional(),
  email: yup.string().trim(),
  person_name: yup.string().trim().matches(specialCharsRegex, message),
  phone_number: yup.string().trim().matches(specialCharsRegex, message),
  dane_code: yup.string().trim().matches(specialCharsRegex, message),
});
