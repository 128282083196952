import React, { useMemo } from 'react'
import { TitleCard } from '../atoms/titleCard'
import { Box, Card, CardContent, Typography } from '@mui/material';
import { styles } from "../styles";
type Props = {
    handleModal?: () => void;
    body:  any;
    title: string;
  };
export const ContactCardQuoter = ({title, handleModal, body}:Props) => {
  const textFiledStyle = useMemo(
    () => ({
      fontSize: "12px!important",
      color: "#677787",
    }),
    []
  );
  return (
    <Card>
    <TitleCard title={title} handleOpenModal={handleModal} validations={body.location_validation} />
    <CardContent sx={{pt: "16px"}}>
      <Typography sx={textFiledStyle}>
        {body?.city}
      </Typography>
      <Box sx={styles.iconAndLabel}>
        {/* <Typography>{body?.country.icon}</Typography> */}
        <Typography sx={textFiledStyle}>
        {body?.country_name}
        </Typography>
      </Box>
    </CardContent>
  </Card>
  )
}
