import { Box, Typography, Link } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormProvider from "commons/form-provider";
import { AddressBookInfoForm } from "components/molecules/address-book-info-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type {
  ConsigneeProps,
  ShipperProps,
  fullFormProp,
} from "components/molecules/order-forms/types";
import { shipperAddressSchema, consigneeAddressSchema } from "./validation";
import { styles } from "components/molecules/order-components/styles";
import { NotebookList } from "components/molecules/order-components/notebookList";
import { AddNotebookButton } from "components/molecules/order-components/addAddressButton";
import { FormActions } from "components/molecules/order-components/FormActions";
import { ADDRESS_TYPE } from "enums/address";
import { SHIPPING_TYPE } from "enums/shipping";

interface Props {
  fnSave: (values: any, isValid: boolean) => Promise<any>;
  isShipper: boolean;
  initialValues: Partial<ShipperProps | ConsigneeProps>;
  handleClose: () => void;
  isForQuote?: boolean;
  formData?: fullFormProp;
  typeShipping?: SHIPPING_TYPE.NATIONAL | SHIPPING_TYPE.INTERNATIONAL;
}

export const AddressForm = ({
  fnSave,
  initialValues,
  isShipper,
  handleClose,
  isForQuote,
  formData,
  typeShipping: typeShippingProp,
}: Props) => {
  const [isValidAddress, setIsValidAddress] = useState(false);
  const { typeShipping: typeShippingParam } = useParams<{
    typeShipping: SHIPPING_TYPE.NATIONAL | SHIPPING_TYPE.INTERNATIONAL;
  }>();
  const typeShipping = typeShippingProp || typeShippingParam;
  const methods = useForm({
    mode: "onSubmit",
    resolver: isForQuote
      ? undefined
      : yupResolver(isShipper ? shipperAddressSchema : consigneeAddressSchema),
    defaultValues: initialValues,
  });

  const {
    getValues,
    handleSubmit,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    let addressIsValid = true;
    if (typeShipping === SHIPPING_TYPE.INTERNATIONAL) {
      if (!isShipper && getValues().country_name === "Colombia") {
        addressIsValid = false;
      }
    }

    if (typeShipping === SHIPPING_TYPE.NATIONAL) {
      if (!isShipper && getValues().country_name !== "Colombia") {
        addressIsValid = false;
      }
    }
    setIsValidAddress(() => addressIsValid);
  }, [
    getValues().country_name,
    typeShipping,
    isValidAddress,
    setIsValidAddress,
  ]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit((data) => fnSave(data, isValid))}
    >
      {!isForQuote && (
        <>
          <Typography sx={{ ...styles.modal.title, mt: 0 }}>
            Directorio
          </Typography>
          <Box sx={styles.notebook}>
            <NotebookList
              addressType={
                isShipper ? ADDRESS_TYPE.SHIPPER : ADDRESS_TYPE.CONSIGNEE
              }
            />
          </Box>

          <hr />
          <Box>
            <Box sx={styles.saveForm}>
              <AddNotebookButton />
            </Box>
          </Box>
        </>
      )}
      <AddressBookInfoForm
        formData={formData}
        isForQuote={isForQuote}
        methods={methods}
        addressType={!isShipper ? ADDRESS_TYPE.CONSIGNEE : ADDRESS_TYPE.SHIPPER}
        typeShipping={typeShipping}
      />

      <Box>
        {!isValidAddress && typeShipping === SHIPPING_TYPE.NATIONAL && (
          <Typography sx={{ ...styles.modal.warning }}>
            El país de destino en envíos nacionales debe ser Colombia. Si
            necesitas hacer un envío internacional, puedes hacerlo aqui
            <Link
              href="/shipping/international"
              sx={{ pl: 1, color: "#7E00FB", fontWeight: "bold" }}
              color="blue"
            >
              Aqui
            </Link>
          </Typography>
        )}
        {!isValidAddress && typeShipping === SHIPPING_TYPE.INTERNATIONAL && (
          <Typography sx={{ ...styles.modal.warning }}>
            El país de destino en envíos internacionales no puede ser Colombia.
            Si necesitas hacer un envío nacional, puedes hacerlo aqui
            <Link
              href="/shipping/national"
              sx={{ pl: 1, color: "#7E00FB", fontWeight: "bold" }}
              color="blue"
            >
              aqui
            </Link>
          </Typography>
        )}
      </Box>
      <Box sx={{ mb: 2 }}>
        <FormActions isValid={true} handleClose={handleClose} />
      </Box>
    </FormProvider>
  );
};
